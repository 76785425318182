import jQuery from 'jquery';
const $ = jQuery;

// MDB Standard (plain JS)
import {
  Alert,
  Collapse,
  Datepicker,
  Dropdown,
  initMDB,
  Input,
  Modal,
  Popover,
  Tooltip,
} from '@vdlp/mdb-ui-kit-pro-advanced';
initMDB({ Alert, Collapse, Dropdown, Modal, Popover, Tooltip, Input, Datepicker });

// PopperJS
import '@popperjs/core';

// Dayjs / SweetAlert 2
import { Dayjs } from 'dayjs';
import Swal from 'sweetalert2';

// useDate
import { useDate } from '@/hooks/use-date';
const { dayjs, fullDateFormat } = useDate();

function initializePlugins(): void {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;

  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // Form Outline
  // document.querySelectorAll('.form-outline').forEach((formOutline) => {
  //   new Input(formOutline).init();
  // });

  // Navigation mobile
  const navbarToggler = document.querySelector('.main-menu-toggler');
  const accountToggler = document.querySelector('.account-toggler');

  if (navbarToggler && accountToggler) {
    const navMainCollapse = new Collapse(document.getElementById('navMain'), {
      toggle: false, // Prevents collapse from toggling on click
    });

    const navAccountCollapse = new Collapse(document.getElementById('navAccount'), {
      toggle: false, // Prevents collapse from toggling on click
    });

    accountToggler.addEventListener('click', () => {
      navMainCollapse.hide();
      navAccountCollapse.toggle();
    });

    navbarToggler.addEventListener('click', () => {
      navAccountCollapse.hide();
      navMainCollapse.toggle();
    });
  }

  // Modal customerServicePhoneNumbers
  const modalcustomerServicePhoneNumbersTrigger = document.querySelector('.selectCustomerServiceLink');

  if (modalcustomerServicePhoneNumbersTrigger) {
    modalcustomerServicePhoneNumbersTrigger.addEventListener('click', function (event) {
      event.preventDefault(); // Prevent the default action

      // Find the modal element
      const modalElement = document.getElementById('customerServicePhoneNumbers');

      // Check if the modal element exists
      if (modalElement) {
        // Initialize the modal
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        // Listen for a click event on the modal's backdrop
        modalElement.addEventListener('click', function (event) {
          // Check if the clicked element is the backdrop of the modal
          if (event.target === modalElement) {
            // Close the modal
            modalInstance.hide();
          }
        });
      } else {
        console.error('Modal element not found');
      }
    });
  }

  // Scroll-to-top
  const scrollToTop = $('.scroll-to-top');
  if (scrollToTop.length > 0) {
    $(window).on('scroll', function () {
      if ((jQuery(this).scrollTop() || 0) > 200) {
        scrollToTop.fadeIn();
      } else {
        scrollToTop.fadeOut();
      }
    });

    scrollToTop.on('click', function () {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        400,
      );
      return false;
    });
  }

  // MDB Standard (Only for plain JS) datepicker addressDateOfEntry field
  const MDBStandardDatepickerElements = document.querySelectorAll<HTMLElement>('.mdb-standard-datepicker');

  for (const MDBStandardDatepickerElement of MDBStandardDatepickerElements) {
    Input.getOrCreateInstance(MDBStandardDatepickerElement);
    const mdbDatepickerInstance = Datepicker.getOrCreateInstance(MDBStandardDatepickerElement);

    const disabledDatesData = MDBStandardDatepickerElement.dataset.vdlpDisabled;

    let disabledDates: Array<Dayjs> = [];

    if (disabledDatesData !== '' && disabledDatesData !== undefined) {
      disabledDates = (JSON.parse(disabledDatesData) as string[]).map((date) => {
        return dayjs(date);
      });
    }

    const minDateData = MDBStandardDatepickerElement.dataset.vdlpMinDate;

    const minDate: Dayjs | undefined = minDateData !== '' && minDateData !== undefined ? dayjs(minDateData) : undefined;

    const filterAddressDateOfEntryDatepicker = (date: Date) => {
      const currentDate = dayjs(date);

      for (const disabledDate of disabledDates) {
        if (currentDate.isSame(disabledDate, 'date')) {
          return false;
        }
      }

      return true;
    };

    mdbDatepickerInstance.update({
      filter: filterAddressDateOfEntryDatepicker,
      confirmDateOnSelect: true,
      min: minDate?.toDate(),
      format: 'dd-mm-yyyy',
      title: 'Selecteer een datum',
      previousMonth: 'Vorige maand',
      nextMonth: 'Volgende maand',
      monthsFull: [
        'Januari',
        'Februari',
        'Maart',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Augustus',
        'September',
        'Oktober',
        'November',
        'December',
      ],
      monthsShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
      weekdaysFull: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
      weekdaysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
      weekdaysNarrow: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
      nextMonthLabel: 'Volgende maand',
      prevMonthLabel: 'Vorige maand',
      nextMultiYearLabel: 'Volgende 24 jaar',
      prevMultiYearLabel: 'Vorige 24 jaar',
      nextYearLabel: 'Volgend jaar',
      prevYearLabel: 'Vorig jaar',
      clearBtnText: 'Wissen',
      cancelBtnText: 'Sluiten',
      cancelBtnLabel: 'Sluiten',
      switchToMultiYearViewLabel: 'Jaar en maand selecteren',
      switchToDayViewLabel: 'Datum selecteren',
      okBtnText: 'Bevestigen',
      invalidLabel: 'Datum of datumnotatie ongeldig',
      toggleIcon: '<i class="fa-solid fa-calendar-days datepicker-toggle-icon"></i>',
      headerTemplateModifier: (selected: Date) => dayjs(selected).format(fullDateFormat.value),
      headerTemplate: `
        <div class="datepicker-title">
          <span class="datepicker-title-text">Selecteer een datum</span>
        </div>
        <div class="datepicker-date">
          <span class="datepicker-date-text">
            <div class="fs-2 mb-2">[selected]</div>
          </span>
        </div>
      `,
    });
  }
}

$(window).on('ajaxUpdateComplete', function () {
  initializePlugins();
});

initializePlugins();

// Ajax validation
addEventListener('ajax:invalid-field', function (event) {
  event.detail.element.classList.add('is-invalid');
});

addEventListener('ajax:promise', function (event) {
  event.detail.context.el.querySelectorAll('.is-invalid').forEach(function (el) {
    el.classList.remove('is-invalid');
  });
});

// Flash message
addEventListener('ajax:setup', function (event) {
  const context = event.detail.context;

  context.options.flash = true;

  // context.options.handleErrorMessage = function (message) {
  //   console.error(message);
  // };

  context.options.handleFlashMessage = function (message, type) {
    window.oc.flashMsg({ text: message, class: type });
  };
});

// SweetAlert 2
window.Swal = Swal.mixin({
  showClass: {
    popup: 'animate__animated animate__fadeInDown',
  },
  hideClass: {
    popup: 'animate__animated animate__fadeOutUp',
  },
});

window.$ = window.jQuery = jQuery;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.bootstrap = { Collapse, Dropdown, Popover, Tooltip, Modal, Alert };
