import 'dayjs/locale/nl';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { Ref, ref } from 'vue';

dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);
dayjs.extend(relativeTime);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);

interface UseDate {
  dayjs: (value?: string | Date | dayjs.Dayjs, format?: string) => dayjs.Dayjs;
  apiDateFormat: string;
  dateFormat: Ref<string>;
  fullDateFormat: Ref<string>;
  formatDate: (date: dayjs.ConfigType) => string;
}

export const useDate = (): UseDate => {
  const timezone = 'Europe/Amsterdam';

  dayjs.locale('nl');
  dayjs.tz.setDefault(timezone);

  const apiDateFormat = 'YYYY-MM-DD';
  const dateFormat = ref('DD-MM-YYYY');
  const fullDateFormat = ref('dddd D MMMM YYYY');
  const formatDate = (date: dayjs.ConfigType): string => dayjs(date).format(dateFormat.value);

  const parse = (value?: string | Date | dayjs.Dayjs, format?: string): dayjs.Dayjs => {
    if (format) {
      return dayjs.tz(value, format, timezone);
    }

    return dayjs.tz(value);
  };

  return {
    dayjs: parse,
    apiDateFormat,
    dateFormat,
    fullDateFormat,
    formatDate,
  };
};
